import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Main from "./modules/main";
import { Router } from "react-router-dom";
import history from "./helpers/history";
import { keycloak, getToken } from "./services/keycloak";
import {
    ACCESS_TOKEN_KEY,
    IS_DEVELOPMENT,
    IS_ON_PREMISE,
    NODE_ENV,
    USER,
} from "./constants/constants";
import Hotjar from "@hotjar/browser";
import { API, APIv2, APIv2_FormData } from "./services/api";
import { Userpilot } from "userpilot";
import * as Sentry from "@sentry/react";
import packageJson from "../package.json";
import "./assets/scss/main.scss";

if (!IS_ON_PREMISE) {
    const siteId = parseInt(process.env.VITE_APP_HOTJAR_SITE_ID || "0");
    const hotjarVersion = 6;
    Hotjar.init(siteId, hotjarVersion);
    Userpilot.initialize(process.env.VITE_APP_USERPILOT_TOKEN as string);
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            retry: false,
        },
    },
});

let userData: any = null;
const localData = window.localStorage.getItem(USER);
if (localData) {
    userData = {
        ...JSON.parse(localData),
        ...{ token: window.localStorage.getItem(ACCESS_TOKEN_KEY) },
    };
}

keycloak.onTokenExpired = async () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(USER);
    await keycloak.logout({ redirectUri: window.location.origin });
    keycloak.login();
};

keycloak
    .init({
        flow: "standard",
        onLoad: "login-required",
        checkLoginIframe: false,
        pkceMethod: "S256",
    })
    .then(async (authenticated: any) => {
        if (authenticated) {
            const token = await getToken();
            localStorage.setItem(ACCESS_TOKEN_KEY, JSON.stringify(token));
            localStorage.setItem(USER, JSON.stringify(keycloak?.tokenParsed));

            Userpilot.identify(keycloak?.tokenParsed?.sub as string, {
                name: `${keycloak?.tokenParsed?.given_name} ${keycloak?.tokenParsed?.family_name}`,
                email: keycloak?.tokenParsed?.email,
                company: {
                    id: keycloak?.tokenParsed?.tenant_id,
                    name: keycloak?.tokenParsed?.tenant_name,
                },
                created_at: keycloak?.tokenParsed?.created_timestamp,
            });

            if (!IS_DEVELOPMENT && !IS_ON_PREMISE) {
                Sentry.init({
                    integrations: [
                        new Sentry.BrowserTracing({
                            routingInstrumentation:
                                Sentry.reactRouterV5Instrumentation(history),
                        }),
                    ],
                    tracesSampleRate: 1.0,
                    dsn: "https://035330a9e9da4aadbfc81bed88e7c81d@o287528.ingest.sentry.io/4506171647393792",
                    release: packageJson?.version || "1.0.0",
                    environment: NODE_ENV,
                });

                Sentry.setUser({
                    email: keycloak?.tokenParsed?.email,
                });
            }

            userData = {
                ...keycloak?.tokenParsed,
                ...{ token },
            };

            if (localStorage.getItem(ACCESS_TOKEN_KEY)) {
                API.defaults.headers.common["Authorization"] =
                    "Bearer " +
                    JSON.parse(localStorage.getItem(ACCESS_TOKEN_KEY) || "");
            }

            if (localStorage.getItem(ACCESS_TOKEN_KEY)) {
                APIv2.defaults.headers.common["Authorization"] =
                    "Bearer " +
                    JSON.parse(localStorage.getItem(ACCESS_TOKEN_KEY) || "");
                APIv2_FormData.defaults.headers.common["Authorization"] =
                    "Bearer " +
                    JSON.parse(localStorage.getItem(ACCESS_TOKEN_KEY) || "");
            }

            ReactDOM.createRoot(
                document.getElementById("root") as HTMLElement
            ).render(
                <QueryClientProvider client={queryClient}>
                    <Router history={history}>
                        <Main
                            authenticated={authenticated}
                            userData={userData}
                        />
                    </Router>
                </QueryClientProvider>
            );
        } else {
            localStorage.removeItem(ACCESS_TOKEN_KEY);
            localStorage.removeItem(USER);
            keycloak.login();
        }
    });
